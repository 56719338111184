.App {
  text-align: left;
  background: linear-gradient(to right, rgb(35, 37, 38), rgb(102, 105, 107)); 
  /* font-family: 'Barlow', sans-serif; */
  font-family: 'Oswald', sans-serif;
}


.App {
  background: linear-gradient(295deg, #000000, #675a5a);
  background-size: 400% 400%;

  -webkit-animation: AnimationName 10s ease infinite;
  -moz-animation: AnimationName 10s ease infinite;
  animation: AnimationName 10s ease infinite;
}

@-webkit-keyframes AnimationName {
  0%{background-position:0% 34%}
  50%{background-position:100% 67%}
  100%{background-position:0% 34%}
}
@-moz-keyframes AnimationName {
  0%{background-position:0% 34%}
  50%{background-position:100% 67%}
  100%{background-position:0% 34%}
}
@keyframes AnimationName {
  0%{background-position:0% 34%}
  50%{background-position:100% 67%}
  100%{background-position:0% 34%}
}


.App-header {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  padding: 1.5rem;
  max-width: 500px;
  margin: 0 auto;
  background-color: #00000050;
}

.text-white {
  color: white;
}


.App-link {
  color: #61dafb;
}

.App-header .bottom-bar {
  /* height: 80px; */
  width: 100%;
  margin: 20px; 
  margin-left: 0;
  margin-bottom: 0;
}

.App-header .list-button { 
  margin: 3px 0;
  color: white;
  width: 100%;
  
}


.App-header .bottom-button { 
  margin: 5px 0;
  color: white;
  width: 100%;
}


.m-0 {
  margin: 0 !important;
}

hr {
  width: 100%;
  border: 1px solid white !important;
}

.item-row {
  font-size: 20px;
  background: #ffffff20;
  width: 100%;
  padding: .5rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: white;
  margin-bottom: .3rem;
  gap: 10;
  color: white;
  border-radius: 5px;
}

.item-row .form-check label p {
  word-break: break-all;

}

.App-header .Latadelixo{
  display: flex;
  position: flex;
  margin: 20x;
  color: white;
  width: 40px; 
  size: 10px;
}

.App-header .item-container{
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.App-header .remove-list-button{
  width: 100%;
}

.App-header .other-bottom-bar {
  overflow: auto;
  display: fixed;
  width: 100%;
  margin: 20px; 
  margin-left: 0;
  margin-bottom: 0;
}

.icon-row{
  display: flex;
  justify-content: space-around;
  gap: 5px;
  margin-bottom: 1rem;
}

.icon-row button{
  border-radius: 50%;
  width: 3rem !important;
  height: 3rem !important;
  padding: 0;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 5px white;;
}
